import axios from 'axios';

import { REACT_APP_API_HOST } from '../config';

const axiosInstance = axios.create({
  baseURL: REACT_APP_API_HOST,
});

axiosInstance.interceptors.response.use(
  response => response,
  error =>
    Promise.reject(
      (error.response && error.response.data) || 'Something went wrong'
    )
);

export default axiosInstance;

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';

import Router from './routes';

import ThemeProvider from './theme';
import NotistackProvider from './components/NotistackProvider';
import { ProgressBarStyle } from './components/ProgressBar';
import { ChartStyle } from './components/chart';
import MotionLazyContainer from './components/animate/MotionLazyContainer';

export default function App() {
  return (
    <ThemeProvider>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <NotistackProvider>
          <MotionLazyContainer>
            <ProgressBarStyle />
            <ChartStyle />
            <Router />
          </MotionLazyContainer>
        </NotistackProvider>
      </LocalizationProvider>
    </ThemeProvider>
  );
}

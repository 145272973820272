import { useTranslation } from 'react-i18next';

import { enUS, zhCN, jaJP, koKR } from '@mui/material/locale';

import cnFlag from '../assets/flags/cn.svg';
import ukFlag from '../assets/flags/uk.svg';
import jpFlag from '../assets/flags/jp.svg';
import koFlag from '../assets/flags/ko.svg';

const LANGS = [
  {
    label: 'Chinese',
    value: 'zh-cn',
    systemValue: zhCN,
    icon: cnFlag,
  },
  {
    label: 'English',
    value: 'en',
    systemValue: enUS,
    icon: ukFlag,
  },
  {
    label: 'Japanese',
    value: 'jp',
    systemValue: jaJP,
    icon: jpFlag,
  },  
  {
    label: 'Korean',
    value: 'ko',
    systemValue: koKR,
    icon: koFlag,
  },    
];

export default function useLocales() {
  const { i18n, t: translate } = useTranslation();
  const langStorage = localStorage.getItem('i18nextLng');
  const currentLang =
    LANGS.find(_lang => _lang.value === langStorage) || LANGS[0];

  const handleChangeLanguage = newlang => {
    i18n.changeLanguage(newlang);
  };

  return {
    onChangeLang: handleChangeLanguage,
    translate,
    currentLang,
    allLang: LANGS,
  };
}

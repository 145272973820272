import PropTypes from 'prop-types';
// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { DatePicker } from '@mui/lab';
import { TextField } from '@mui/material';

import useLocales from '../../hooks/useLocales';

// ----------------------------------------------------------------------

RHFDatePicker.propTypes = {
  name: PropTypes.string,
};

export default function RHFDatePicker({ name, value, ...other }) {
  const { control } = useFormContext();

  const { translate } = useLocales();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <DatePicker
          {...field}
          fullWidth
          error={!!error}
          helperText={translate(error?.message)}
          {...other}
          renderInput={params => <TextField fullWidth {...params} />}
        />
      )}
    />
  );
}
